<template>
  <div>
    <div v-if="value" class="back-drop"></div>
    <div class="modal" :class="{'fade in d-block':value,'d-none': !value}" id="myModal" tabindex="-1" role="dialog" ref="modalBackDrop">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="$emit('input', false)" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{ modalTitle }}</h4>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button v-if="cancelButtonLabel" @click="$emit('input', false)" type="button" class="btn btn-default">{{ cancelButtonLabel }}</button>
            <button v-if="okButtonLabel" @click="()=>{this.$emit('ok-button-click');this.$emit('okButtonClick')}" type="button" class="btn btn-primary">{{ okButtonLabel }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortalModal",
  props: {
    value: Boolean,
    modalTitle: String,
    cancelButtonLabel: String,
    okButtonLabel: String,
  },
  methods: {
    modalBackDrop(e) {
      if ( e.target.classList.contains('back-drop-modal') ) {
        this.$emit('input', false);
      }
    }
  }
}
</script>

<style scoped>

.min-width-500 {
  min-width: 500px !important;
}

.fade {
  -webkit-transition: opacity .15s linear !important;
  -o-transition: opacity .15s linear !important;
  transition: opacity .15s linear !important;
}

.back-drop {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
}

/*.modal{*/
/*  position: absolute;*/
/*  top: 50% !important;*/
/*  left: 50% !important;*/
/*  -ms-transform: translate(-50%, -50%) !important;*/
/*  transform: translate(-50%, -50%) !important;*/
/*  max-height: 100%;*/
/*}*/

.back-drop-modal {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  overflow: auto;
}

.alert__close {
  top: 20px !important;
  right: 20px !important;
  border: none;
  background-color: transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  font-size: 25px;
}

.alert__close:hover {
  opacity: 1;
}
</style>